const getters = {
  getOrderCategory: (state) => (cateId = 1) => {
    if (cateId) {
      return state.orderCategory.find((cate) => cate.id == cateId).name;
    }
  },
  getOrderStatus: (state) => (statusId = 1) => {
    return state.orderStatus.find((s) => s.id == statusId).label;
  },
};

export default getters;
