<template>
  <div>
    <my-header></my-header>
    <router-view></router-view>
  </div>
</template>

<script>
import MyHeader from "components/content/MyHeader";

export default {
  name: "App",
  components: { MyHeader },
  setup() {},
};
</script>

<style>
@import "assets/css/base.css";
html,
body {
  height: 100%;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
